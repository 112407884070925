@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,700;0,800;1,300&display=swap");
.btn-primary {
  background: #662C92;
  color: #fff;
  text-decoration: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 900;
}
@media screen and (min-width: 63em) {
  .btn-primary {
    font-size: 1.4rem;
  }
}
.btn-primary:hover {
  background-color: #CDDC39;
  color: #000;
}

.btn-secondary {
  border: 5px solid #662C92;
  color: #662C92;
  font-size: 1rem;
  font-weight: 900;
  padding: 1rem 2rem;
  text-decoration: none;
}
.btn-secondary.btn-yellow {
  border: 5px solid #CDDC39;
  color: #CDDC39;
}
.btn-secondary.btn-yellow:hover {
  background-color: #CDDC39;
  color: #000;
}

.btn-large {
  font-size: 1.3rem;
}
@media screen and (min-width: 63em) {
  .btn-large {
    font-size: 1.6rem;
  }
}

.loading-screen {
  width: 100%;
  display: block;
}
.loading-screen .react-bodymovin-container svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px !important;
  height: 300px !important;
}
@media screen and (min-width: 37em) {
  .loading-screen .react-bodymovin-container svg {
    width: 550px !important;
    height: 550px !important;
  }
}
@media screen and (min-width: 63em) {
  .loading-screen .react-bodymovin-container svg {
    width: 850px !important;
    height: 850px !important;
  }
}

header.sub-header {
  position: relative;
  overflow: hidden;
}
header.sub-header .sticky-header {
  position: relative;
  z-index: 15;
  transition: all 0.25s;
}
header.sub-header .sticky-header .innovate-cta {
  display: none;
}
header.sub-header .sticky-header a.logo {
  display: block;
  border: none;
  width: 90px;
  padding: 1em;
}
@media screen and (min-width: 63em) {
  header.sub-header .sticky-header a.logo {
    width: 150px;
  }
}
@media screen and (min-width: 75em) {
  header.sub-header .sticky-header a.logo {
    width: 195px;
  }
}
header.sub-header .sticky-header a.logo img {
  width: 100%;
  border: none;
}
header.sub-header .sticky-header .mobile-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1em;
}
header.sub-header .sticky-header .mobile-btn-wrapper .mobile-toggle button {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: bold;
}
header.sub-header .sticky-header .mobile-btn-wrapper .mobile-toggle button span {
  display: block;
  padding-right: 5px;
}
header.sub-header .sticky-header .mobile-btn-wrapper .mobile-toggle button i {
  font-size: 35px;
}
@media screen and (min-width: 63em) {
  header.sub-header .sticky-header .mobile-btn-wrapper .mobile-toggle button i {
    padding-top: 0px;
  }
}
header.sub-header .sticky-header.scrolling-header {
  position: fixed;
  height: 80px;
  z-index: 30;
  top: 0;
  background: #fff;
  width: 100%;
  transition: all 0.5s;
  border-bottom: 5px solid #662C92;
}
header.sub-header .sticky-header.scrolling-header .container, header.sub-header .sticky-header.scrolling-header .row {
  height: 100%;
}
header.sub-header .sticky-header.scrolling-header a.logo {
  border: 0;
  display: block;
}
header.sub-header .sticky-header.scrolling-header a.logo img {
  width: 150px;
  height: auto;
}
@media screen and (min-width: 37em) {
  header.sub-header .sticky-header.scrolling-header a.logo {
    width: 175px;
    height: auto;
  }
}
@media screen and (min-width: 63em) {
  header.sub-header .sticky-header.scrolling-header a.logo {
    width: 195px;
  }
}
@media screen and (min-width: 75em) {
  header.sub-header .sticky-header.scrolling-header .innovate-cta {
    display: block;
    text-align: center;
  }
  header.sub-header .sticky-header.scrolling-header .innovate-cta a {
    width: 100%;
    height: 100%;
    color: #2f2e41;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.85em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  header.sub-header .sticky-header.scrolling-header .innovate-cta a:visited {
    color: #2f2e41;
  }
  header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
    color: #00E422;
  }
  header.sub-header .sticky-header.scrolling-header .innovate-cta span {
    text-decoration: underline;
  }
}
header.sub-header .main-navigation .header-intro {
  display: none;
}
header.sub-header .main-navigation .header-intro h1 {
  font-size: 3em;
  line-height: 1em;
  letter-spacing: -0.05em;
}
@media screen and (min-width: 63em) {
  header.sub-header .main-navigation .header-intro h1 {
    font-size: 6em;
  }
}
@media screen and (min-width: 75em) {
  header.sub-header .main-navigation .header-intro h1 {
    font-size: 10em;
    line-height: 1em;
    letter-spacing: -0.05em;
  }
}
header.sub-header .main-navigation ul {
  list-style: none;
  padding-left: 2em;
}
header.sub-header .main-navigation ul li a {
  color: #2f2e41;
  font: normal 900 2.2em "Montserrat", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}
header.sub-header .main-navigation ul li a:hover {
  animation-name: link-gradient;
  background: -webkit-linear-gradient(right, #DE1858 50%, #321030);
  background: -o-linear-gradient(to left, #DE185A 50%, #321030);
  background: -moz-linear-gradient(to left, #DE185A 50%, #321030);
  background-size: 400%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -o-text-fill-color: transparent;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
}
@media screen and (min-width: 37em) {
  header.sub-header .main-navigation ul {
    padding-left: 2em;
  }
  header.sub-header .main-navigation ul li a {
    font-size: 4em;
  }
}
@media screen and (min-width: 75em) {
  header.sub-header .main-navigation ul {
    padding-left: 4em;
  }
  header.sub-header .main-navigation ul li a {
    font-size: 6em;
  }
}
header.sub-header .hero-wrapper .container {
  position: relative;
  z-index: 8;
}
header.sub-header .hero-wrapper .container .shared-hero {
  padding: 1em 1.5em 2em 1.5em;
}
@media screen and (min-width: 75em) {
  header.sub-header .hero-wrapper .container .shared-hero {
    width: 85%;
    padding: 2.5em 1.5em 2em 1.5em;
  }
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1em 0;
  cursor: pointer;
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #00E422;
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i.approach-back-arrow {
  color: #662C92;
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i.company-back-arrow {
  color: #CDDC39;
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i.work-back-arrow {
  color: #00E422;
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i.lab-back-arrow {
  color: #E00E58;
}
@media screen and (min-width: 63em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
    padding-left: 1em;
  }
}
header.sub-header .hero-wrapper .container .shared-hero .hero-page-title span {
  color: #2f2e41;
  display: inline-block;
  padding-left: 0.75em;
}
@media screen and (min-width: 63em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-page-title span {
    padding-left: 0.75em;
  }
}
@media screen and (min-width: 63em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-page-title:hover i {
    padding-left: 0;
    transition: all 2s;
  }
  header.sub-header .hero-wrapper .container .shared-hero .hero-page-title:hover span {
    padding-left: 1.75em;
    transition: all 2s;
  }
}
header.sub-header .hero-wrapper .container .shared-hero .hero-wrap {
  padding-left: 1.5em;
  position: relative;
  animation: hero-wrap 1.5s;
}
@media screen and (min-width: 63em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-wrap {
    padding-left: 2.5em;
  }
}
header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading {
  color: #fff;
  font-size: 1.5em;
  line-height: 1em;
  text-transform: uppercase;
  margin: 0.25em 0 0.25em 0;
}
@media screen and (min-width: 37em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading {
    font-size: 2.2em;
  }
}
@media screen and (min-width: 63em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading {
    font-size: 3.5em;
  }
}
@media screen and (min-width: 75em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading {
    font-size: 5em;
  }
}
header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-copy {
  font-size: 1.2em;
  color: #fff;
}
@media screen and (min-width: 63em) {
  header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-copy {
    font-size: 1.5em;
  }
}
header.sub-header .hero-wrapper .react-bodymovin-container {
  text-align: center;
  top: -670px;
  position: absolute;
  left: 0;
  min-height: 100vh;
  width: 250vw;
}
header.sub-header .hero-wrapper .react-bodymovin-container svg {
  min-height: 1605px;
}
@media screen and (min-width: 75em) {
  header.sub-header .hero-wrapper .react-bodymovin-container {
    top: -200px;
    width: 130vw;
  }
  header.sub-header .hero-wrapper .react-bodymovin-container svg {
    min-height: 1400px;
  }
}

@keyframes hero-wrap {
  0% {
    top: 500px;
    opacity: 0;
  }
  50% {
    top: 500px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}
@keyframes link-gradient {
  100% {
    background-position: 100%;
  }
}
footer.site-footer .site-footer-cta {
  background-color: #662C92;
  padding: 2rem 0;
  color: #fff;
}
footer.site-footer .site-footer-cta .footer-col-one p, footer.site-footer .site-footer-cta .footer-col-two p {
  padding: 2rem 0;
}
footer.site-footer .site-footer-cta .footer-col-one h2, footer.site-footer .site-footer-cta .footer-col-two h2 {
  color: #CDDC39;
  font-size: 1.75rem;
  line-height: 1.6rem;
}
footer.site-footer .site-footer-cta .footer-col-one {
  padding-bottom: 4rem;
}
@media screen and (min-width: 63em) {
  footer.site-footer .site-footer-cta .footer-col-one {
    padding-right: 2rem;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 37em) {
  footer.site-footer .subscribe-form {
    margin-top: 10px;
    height: 70px;
  }
}
footer.site-footer .subscribe-form label {
  font-weight: bold;
}
footer.site-footer .subscribe-form .subscribe-input-area {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
footer.site-footer .subscribe-form .subscribe-input-area input[type=email] {
  font-size: 1rem;
  padding: 0.9rem 0.5rem;
  width: 70%;
}
@media screen and (min-width: 63em) {
  footer.site-footer .subscribe-form .subscribe-input-area input[type=email] {
    width: auto;
  }
}
@media screen and (min-width: 75em) {
  footer.site-footer .subscribe-form .subscribe-input-area input[type=email] {
    width: 50%;
  }
}
footer.site-footer .subscribe-form .subscribe-input-area input[type=email]:focus {
  outline: 5px solid #4A1671;
  outline-offset: -5px;
}
footer.site-footer .subscribe-form .subscribe-btn {
  background: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  padding: 0 0.75rem;
  font: normal 900 1.75rem "Montserrat", sans-serif;
  cursor: pointer;
}
footer.site-footer .subscribe-form .subscribe-btn:hover {
  background-color: #CDDC39;
}
footer.site-footer .subscribe-form .subscribe-btn.btn-loading {
  background: none;
  color: #4A1671;
  border: 5px solid #4A1671;
  border-color: #4A1671;
  cursor: not-allowed;
}
footer.site-footer .subscribe-form .subscribe-btn.btn-loading img {
  height: 26px;
  width: 26px;
  animation: rotate-spinner 1s infinite;
  opacity: 1;
}
footer.site-footer .subscribe-form .subscribe-btn span {
  display: none;
}
@media screen and (min-width: 63em) {
  footer.site-footer .subscribe-form .subscribe-btn span {
    display: block;
  }
}
footer.site-footer .error-text {
  padding-top: 8px;
  color: #F8C5C5;
}
footer.site-footer .success-text {
  padding-top: 8px;
  color: #CDDC39;
}
footer.site-footer .link {
  color: #CDDC39;
  font: normal 400 26px/154% "Montserrat", sans-serif;
  text-decoration-line: underline;
}
footer.site-footer .footer-contact {
  background-color: #4A1671;
  padding-bottom: 70px;
}
footer.site-footer .footer-contact span.footer-label {
  color: #CDDC39;
  display: block;
  font-size: 1.125rem;
  font-weight: 900;
  margin-bottom: 15px;
  margin-top: 25px;
}
@media screen and (min-width: 63em) {
  footer.site-footer .footer-contact span.footer-label {
    margin-bottom: 35px;
  }
}
footer.site-footer .footer-contact div {
  padding-top: 10px;
  color: #fff;
}
footer.site-footer .footer-contact a {
  color: #FFF;
}
footer.site-footer .footer-contact a:active, footer.site-footer .footer-contact a:hover {
  color: #CDDC39;
}
footer.site-footer .footer-contact a:visited {
  color: #fff;
}
footer.site-footer .footer-contact .footer-address {
  line-height: 1.35rem;
  color: #fff;
  text-decoration: none;
}
footer.site-footer .footer-contact .footer-address address {
  font-style: normal;
}
footer.site-footer .footer-contact .footer-address address span {
  display: block;
}
footer.site-footer .footer-contact .footer-contact-info {
  margin: 1.25rem 0;
}
@media screen and (min-width: 37em) {
  footer.site-footer .footer-contact .footer-contact-info {
    margin: 0 0;
  }
}
footer.site-footer .footer-contact .contact-links {
  display: flex;
  flex-direction: column;
}
footer.site-footer .footer-contact .contact-links a:first-child {
  margin-bottom: 10px;
}
footer.site-footer .footer-contact .footer-connect ul {
  list-style: none;
  padding: 0px;
  display: flex;
}
footer.site-footer .footer-contact .footer-connect ul li {
  margin: 10px 20px 10px -5px;
}
footer.site-footer .footer-contact .footer-connect ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #662C92;
  color: #fff;
}
footer.site-footer .footer-contact .footer-connect ul li a:hover, footer.site-footer .footer-contact .footer-connect ul li a:active {
  background-color: #CDDC39;
  color: #000;
}
footer.site-footer .footer-contact .footer-connect ul li a i {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2f2e41;
}
body * {
  font-family: "Montserrat", sans-serif;
}
body p, body ul, body li {
  font-size: 1.125rem;
}
body p {
  line-height: 1.7rem;
}
body section.content-body a, body .our-work-details .details-content a {
  color: #2f2e41;
  font-weight: 700;
}
body section.content-body a:not(.wp-block-file__button, .wp-block-button a, .wp-block-heading a), body .our-work-details .details-content a:not(.wp-block-file__button, .wp-block-button a, .wp-block-heading a) {
  text-decoration: underline;
}
body .more-work-col a:hover {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.5rem;
  line-height: 2.3rem;
}
@media screen and (min-width: 63em) {
  h2 {
    font-size: 3rem;
    line-height: 2.8rem;
  }
}

h3 {
  font-size: 1.8rem;
  line-height: 2rem;
}
@media screen and (min-width: 63em) {
  h3 {
    font-size: 2rem;
    line-height: 1.85rem;
  }
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1rem;
}

.the-lab header.sub-header .sticky-header, .the-lab-detail header.sub-header .sticky-header {
  border-color: #E00E58;
}
.the-lab header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover, .the-lab-detail header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
  color: #E00E58;
}
.the-lab header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i, .the-lab-detail header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #E00E58;
}
.the-lab header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading, .the-lab-detail header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading {
  color: #E00E58;
}
.the-lab .blog-posts .blog-snippet, .the-lab-detail .blog-posts .blog-snippet {
  padding-bottom: 10px;
}
.the-lab .blog-posts .blog-snippet:last-child .row, .the-lab-detail .blog-posts .blog-snippet:last-child .row {
  border-bottom: none;
}
.the-lab .blog-posts .blog-snippet .row, .the-lab-detail .blog-posts .blog-snippet .row {
  border-bottom: 1px solid #B2BBBE;
  flex-direction: row-reverse;
}
.the-lab .blog-posts .blog-snippet .blog-blurb h2, .the-lab-detail .blog-posts .blog-snippet .blog-blurb h2 {
  font-size: 1em;
  font-weight: 800;
  text-transform: uppercase;
}
@media screen and (min-width: 37em) {
  .the-lab .blog-posts .blog-snippet .blog-blurb h2, .the-lab-detail .blog-posts .blog-snippet .blog-blurb h2 {
    font-size: 1.5em;
  }
}
@media screen and (min-width: 63em) {
  .the-lab .blog-posts .blog-snippet .blog-blurb h2, .the-lab-detail .blog-posts .blog-snippet .blog-blurb h2 {
    font-size: 2.5em;
  }
}
.the-lab .blog-posts .blog-snippet .blog-blurb h2 a, .the-lab-detail .blog-posts .blog-snippet .blog-blurb h2 a {
  text-decoration: none;
  color: #2f2e41;
}
.the-lab .blog-posts .blog-snippet .blog-blurb h2 a:hover:after, .the-lab-detail .blog-posts .blog-snippet .blog-blurb h2 a:hover:after {
  font-family: FontAwesome;
  font-size: 0.5em;
  display: table-cell;
  vertical-align: top;
  line-height: 18px;
  content: "\f061";
  color: #00E422;
  padding-left: 10px;
}
@media screen and (min-width: 63em) {
  .the-lab .blog-posts .blog-snippet .blog-blurb h2 a:hover:after, .the-lab-detail .blog-posts .blog-snippet .blog-blurb h2 a:hover:after {
    line-height: 35px;
  }
}
.the-lab .blog-posts .blog-snippet .blog-snippet-image, .the-lab-detail .blog-posts .blog-snippet .blog-snippet-image {
  text-align: center;
}
@media screen and (min-width: 63em) {
  .the-lab .blog-posts .blog-snippet .blog-snippet-image, .the-lab-detail .blog-posts .blog-snippet .blog-snippet-image {
    text-align: right;
  }
}
.the-lab .blog-posts .blog-snippet .blog-snippet-image img, .the-lab-detail .blog-posts .blog-snippet .blog-snippet-image img {
  height: auto;
  width: auto;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.the-lab .blog-scroll img, .the-lab-detail .blog-scroll img {
  height: auto;
  width: auto;
  max-height: 300px;
  max-width: 300px;
}
@media screen and (min-width: 63em) {
  .the-lab .blog-scroll img, .the-lab-detail .blog-scroll img {
    max-width: 350px;
    max-height: 350px;
  }
}
@media screen and (min-width: 75em) {
  .the-lab .blog-scroll img, .the-lab-detail .blog-scroll img {
    max-width: 400px;
    max-height: 400px;
  }
}
.the-lab .blog-scroll h2, .the-lab-detail .blog-scroll h2 {
  font-size: 1.5em;
  font-weight: 800;
}
@media screen and (min-width: 63em) {
  .the-lab .blog-scroll h2, .the-lab-detail .blog-scroll h2 {
    font-size: 2em;
  }
}
.the-lab .blog-scroll h2 a, .the-lab-detail .blog-scroll h2 a {
  text-decoration: none;
  color: #2f2e41;
}
.the-lab .blog-scroll h2 a:hover, .the-lab-detail .blog-scroll h2 a:hover {
  color: #B2BBBE;
}
.the-lab .older-posts, .the-lab-detail .older-posts {
  border-top: 2px solid #B2BBBE;
  border-bottom: 2px solid #B2BBBE;
  padding: 15px 0;
  margin-bottom: 2rem;
}
.the-lab .older-posts button, .the-lab-detail .older-posts button {
  font-size: 0.8em;
  border: none;
  background: none;
  font-weight: bold;
  color: #2f2e41;
  text-decoration: none;
  cursor: pointer;
}
.the-lab .older-posts button.older-posts-btn, .the-lab-detail .older-posts button.older-posts-btn {
  float: right;
}
.the-lab .older-posts button.older-posts-btn:after, .the-lab-detail .older-posts button.older-posts-btn:after {
  font-family: FontAwesome;
  content: "\f054";
  color: #B2BBBE;
  padding-left: 5px;
}
.the-lab .older-posts button.older-posts-btn:hover:after, .the-lab-detail .older-posts button.older-posts-btn:hover:after {
  color: #00E422;
}
.the-lab .older-posts button.recent-posts-btn, .the-lab-detail .older-posts button.recent-posts-btn {
  float: left;
}
.the-lab .older-posts button.recent-posts-btn:before, .the-lab-detail .older-posts button.recent-posts-btn:before {
  font-family: FontAwesome;
  content: "\f053";
  color: #B2BBBE;
  padding-right: 5px;
}
.the-lab .older-posts button.recent-posts-btn:hover:before, .the-lab-detail .older-posts button.recent-posts-btn:hover:before {
  color: #00E422;
}

.sign-up-form {
  -ms-flex: 0 1 auto;
  color: #662C92;
  text-align: center;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
}
.sign-up-form h2 {
  font-size: 2em;
}
@media screen and (min-width: 63em) {
  .sign-up-form {
    padding-left: 10%;
    padding-right: 10%;
  }
  .sign-up-form h2 {
    font-size: 3em;
  }
}
.sign-up-form button {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 2em;
  background-color: #FFF;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border: 4px solid #662C92;
  cursor: pointer;
}
.sign-up-form button:hover {
  background: #662C92;
  color: #fff;
}
.sign-up-form input {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.5em;
  color: #2f2e41;
  width: 80%;
  margin-bottom: 20px;
  border-style: none none solid none;
  border-color: #B2BBBE;
}
.sign-up-form input::placeholder {
  color: #2f2e41;
  font-size: 0.8em;
}
.sign-up-form input:focus {
  outline: none;
}

pre > code {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #662C92;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

p > code {
  font-family: monospace;
  font-size: 16px;
}

.the-lab-detail header.sub-header .hero-wrapper .container .shared-hero .hero-wrap {
  padding-bottom: 2em;
}
@media screen and (min-width: 63em) {
  .the-lab-detail header.sub-header .hero-wrapper .container .shared-hero .hero-wrap {
    padding-bottom: 3.5em;
  }
}
.the-lab-detail .hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.the-lab-detail .blog-main-body {
  flex-direction: row-reverse;
}
.the-lab-detail .blog-main-body .blog-post-info p {
  font-weight: 800;
  border-bottom-color: #B2BBBE;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
@media screen and (min-width: 63em) {
  .the-lab-detail .blog-main-body .blog-post-info p {
    padding-top: 30px;
  }
}
.the-lab-detail .blog-main-body .blog-post-info button {
  background-color: white;
  font-size: 1em;
  padding: 15px 40px 15px 40px;
  margin-bottom: 10px;
  margin-right: 10px;
  float: left;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
}
.the-lab-detail .blog-main-body .blog-post-info button a {
  text-decoration: none;
  color: black;
}
@media screen and (min-width: 63em) {
  .the-lab-detail .blog-main-body .blog-post-info button {
    width: 160px;
  }
}
.the-lab-detail .social-share h4 {
  font-size: 1em;
  font-weight: 100;
}
.the-lab-detail .social-share a {
  text-decoration: none;
  color: #2f2e41;
  font-weight: bold;
}
.the-lab-detail .social-share a:hover {
  color: #00E422;
}
.the-lab-detail .social-share ul {
  list-style: none;
  padding-left: 0;
  font-weight: 800;
}
.the-lab-detail .social-share ul li {
  display: inline;
}
.the-lab-detail .small-sign-up-form {
  font-size: 1em;
  color: #662C92;
  font-weight: 800;
}
.the-lab-detail .small-sign-up-form button {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1em;
  margin-top: 20px;
  background-color: white;
  border-color: #662C92;
  border-width: 4px;
  border-style: solid;
}
.the-lab-detail .small-sign-up-form input {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1em;
  width: 60%;
  margin-bottom: 20px;
  margin-right: 20px;
  border-style: none none solid none;
  border-color: #b2bbbe;
}
.the-lab-detail .small-sign-up-form input:focus {
  outline: none;
}
.the-lab-detail .centered {
  margin-top: 30px;
  justify-content: center;
  border-color: #B2BBBE;
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
}
.the-lab-detail .centered .comments button {
  background-color: white;
  font-weight: 800;
  font-size: 1em;
  border-style: none;
  border-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.the-lab-detail .centered .comments button a {
  text-decoration: none;
  color: #00E422;
}
.the-lab-detail .centered .comments button a:after {
  font-family: FontAwesome;
  content: "\f063";
  padding-left: 4px;
  color: #B2BBBE;
  font-size: 0.8em;
}
.the-lab-detail .blog-snippet {
  padding-top: 10px;
  padding-bottom: 10px;
}
.the-lab-detail .blog-snippet .snippet .blog-blurb {
  width: 100%;
}
.the-lab-detail .blog-snippet .snippet .blog-blurb a {
  text-decoration: none;
  color: black;
}
@media screen and (min-width: 63em) {
  .the-lab-detail .blog-snippet .snippet .blog-blurb a:hover {
    color: #E00E58;
  }
}
.the-lab-detail .blog-snippet .snippet .blog-blurb a:hover:after {
  font-family: FontAwesome;
  font-size: 0.5em;
  display: table-cell;
  vertical-align: top;
  line-height: 18px;
  content: "\f061";
  color: #00E422;
  padding-left: 10px;
}
@media screen and (min-width: 63em) {
  .the-lab-detail .blog-snippet .snippet .blog-blurb a:hover:after {
    content: none;
  }
}
.the-lab-detail .blog-snippet-image {
  text-align: center;
}
.the-lab-detail .blog-snippet-image img {
  height: auto;
  max-width: 100%;
  padding-top: 20px;
}

section.content-body h2 {
  font-size: 2em;
}
@media screen and (min-width: 63em) {
  section.content-body h2 {
    font-size: 3em;
  }
}
section.content-body a {
  text-decoration: none;
  color: #2f2e41;
  font-weight: bold;
}
section.content-body a:hover {
  color: #00E422;
}
section.content-body ol {
  line-height: 1.7em;
}
section.content-body ul {
  line-height: 1.7em;
}
section.content-body img {
  max-width: 100%;
  height: auto;
  padding: 10px 0 10px 0;
}
section.content-body .alignright {
  margin: auto;
  display: block;
}
@media screen and (min-width: 63em) {
  section.content-body .alignright {
    float: right;
    padding-left: 15px;
  }
}
section.content-body .alignleft {
  margin: auto;
  display: block;
}
@media screen and (min-width: 63em) {
  section.content-body .alignleft {
    float: left;
    padding-right: 15px;
  }
}
section.content-body .aligncenter {
  margin: auto;
  display: block;
}
section.content-body .wp-caption-text {
  font-size: 0.75em;
}

div.privacy-policy header.sub-header {
  background: #00E422;
}
div.privacy-policy header.sub-header .sticky-header {
  border-color: #662C92;
}
div.privacy-policy header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
  color: #662C92;
}
div.privacy-policy header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #662C92;
}

.project {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.project .project-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 40px;
}
.project .project-container a {
  border-color: #662C92;
  border-style: solid;
  border-width: thick;
  text-decoration: none;
  color: #2f2e41;
  font: normal 900 1em "Montserrat", sans-serif;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.project .project-container a i.fa {
  font-size: 20px;
  padding-left: 10px;
}
@media screen and (min-width: 63em) {
  .project .project-container a i.fa {
    padding-left: 15px;
    font-size: 50px;
  }
}
.project .project-container a:hover {
  animation: start-project-wrap 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.project .project-container a:hover i.fa {
  animation: project-arrow-wrap 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@media screen and (min-width: 63em) {
  .project .project-container a {
    font: normal 900 2.5em "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 37em) {
  .project .project-container {
    padding-top: 40px;
  }
}

@keyframes start-project-wrap {
  from {
    border-color: #662C92;
  }
  to {
    border-color: #00E422;
  }
}
@keyframes project-arrow-wrap {
  from {
    color: #2f2e41;
  }
  to {
    color: #00E422;
  }
}
.navigation-overlay {
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
}
.navigation-overlay .overlay-header {
  height: 80px;
  align-items: center;
}
.navigation-overlay .overlay-header .container, .navigation-overlay .overlay-header .row {
  height: 100%;
}
.navigation-overlay .overlay-header .overlay-logo-wrapper {
  display: flex;
  align-items: center;
}
.navigation-overlay .overlay-header .overlay-logo-wrapper a.logo-glyph {
  display: block;
  padding-left: 1em;
}
.navigation-overlay ul {
  list-style: none;
  text-align: center;
  padding-top: 10px;
  padding-left: 0;
}
.navigation-overlay ul li {
  padding-bottom: 20px;
}
.navigation-overlay ul li a {
  text-decoration: none;
  color: #2f2e41;
  display: block;
  padding: 5px;
  font: normal 900 2em "Montserrat", sans-serif;
}
.navigation-overlay ul li a:hover {
  color: #00E422;
}
@media screen and (min-width: 37em) {
  .navigation-overlay ul li a {
    padding: 10px;
  }
}
@media screen and (min-width: 63em) {
  .navigation-overlay ul {
    padding-top: 100px;
  }
  .navigation-overlay ul li a {
    font: normal 900 3em "Montserrat", sans-serif;
  }
}
@media screen and (min-width: 37em) {
  .navigation-overlay ul {
    padding-top: 100px;
  }
}

body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,rgb(255,206,236) 0%,rgb(152,150,240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg,rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg,rgb(202,248,128) 0%,rgb(113,206,126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
}

:where(.is-layout-flex) {
  gap: 0.5em;
}

body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
  display: flex;
}

body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}

body .is-layout-flex > * {
  margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

.has-black-color {
  color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
  color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-button a.wp-block-button__link, .wp-block-button .wp-block-file__button, .wp-block-file a.wp-block-button__link, .wp-block-file .wp-block-file__button, :not(.is-style-outline) a.wp-block-button__link, :not(.is-style-outline) .wp-block-file__button {
  background-color: #662C92;
  color: #fff;
}
.wp-block-button a.wp-block-button__link:hover, .wp-block-button .wp-block-file__button:hover, .wp-block-file a.wp-block-button__link:hover, .wp-block-file .wp-block-file__button:hover, :not(.is-style-outline) a.wp-block-button__link:hover, :not(.is-style-outline) .wp-block-file__button:hover {
  background-color: #450A72;
  color: #fff;
}

.home h2 {
  font-size: 1.75rem;
  line-height: 1.6rem;
  text-transform: uppercase;
}
.home section.testimonials h2 {
  width: 80%;
}
@media screen and (min-width: 63em) {
  .home section.testimonials h2 {
    width: 30%;
  }
}
@media screen and (min-width: 75em) {
  .home section.testimonials h2 {
    width: 25%;
  }
}

section.home-hero h1 {
  font-size: 2.4rem;
  margin-top: 0;
  padding-bottom: 2rem;
}
section.home-hero h1 span {
  border-bottom: 6px solid #662C92;
}
@media screen and (min-width: 63em) {
  section.home-hero h1 {
    font-size: 3.2rem;
  }
  section.home-hero h1 span {
    border-bottom: 10px solid #662C92;
  }
}
@media screen and (min-width: 75em) {
  section.home-hero h1 {
    font-size: 4.68rem;
  }
}

.carousel-wrapper {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}
.carousel-wrapper .carousel-scroll {
  width: 100%;
  white-space: nowrap;
  display: flex;
}
.carousel-wrapper .carousel-block {
  margin: 0 20px;
  flex-wrap: wrap;
  flex-direction: row;
  background-size: cover;
  color: #fff;
  text-transform: uppercase;
}
.carousel-wrapper .carousel-block h2 {
  font-size: 1.7em;
  line-height: 1.2em;
  padding: 3px 0;
  margin: 0;
}
@media screen and (min-width: 63em) {
  .carousel-wrapper .carousel-block h2 {
    font-size: 2.6em;
    padding: 0.5em 0;
  }
}
.carousel-wrapper .carousel-block:hover a .block-inside {
  transition: all 0.5s;
}
.carousel-wrapper .carousel-block:hover.the-lab a .block-inside {
  border: 4px solid #E00E58;
}
.carousel-wrapper .carousel-block:hover.the-lab a .block-inside h2 {
  color: #E00E58;
}
.carousel-wrapper .carousel-block:hover.case-study a .block-inside {
  border: 4px solid #00E422;
}
.carousel-wrapper .carousel-block:hover.case-study a .block-inside h2 {
  color: #00E422;
}
.carousel-wrapper .carousel-block .carousel-block-shade {
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
.carousel-wrapper .carousel-block .carousel-block-shade:hover {
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.75);
}
.carousel-wrapper .carousel-block .carousel-block-shade a {
  display: flex;
  display: -ms-flexbox;
  color: #fff;
  text-decoration: none;
  width: 100%;
}
.carousel-wrapper .carousel-block .carousel-block-shade a:visited {
  color: #fff;
}
.carousel-wrapper .carousel-block .carousel-block-shade a:hover .block-inside {
  width: 88%;
  margin: 20px;
  padding: 20px;
}
@media screen and (min-width: 63em) {
  .carousel-wrapper .carousel-block .carousel-block-shade a:hover .block-inside {
    width: 76%;
    margin: 40px;
    padding: 40px;
  }
  .carousel-wrapper .carousel-block .carousel-block-shade a .block-inside {
    width: 76%;
    padding: 80px;
  }
}
.carousel-wrapper .carousel-block .carousel-block-shade a .block-inside {
  width: 88%;
  height: 260px;
  margin: 0;
  padding: 40px;
  border: 4px solid transparent;
  white-space: normal;
  display: flex;
}
@media screen and (min-width: 63em) {
  .carousel-wrapper .carousel-block .carousel-block-shade a .block-inside {
    width: 76%;
    padding: 80px;
  }
  .carousel-wrapper .carousel-block .carousel-block-shade a .block-inside:hover {
    width: 76%;
    margin: 40px;
    padding: 40px;
  }
}
@media screen and (min-width: 63em) {
  .carousel-wrapper .carousel-block .carousel-block-shade a .block-inside {
    height: 475px;
  }
}
.carousel-wrapper .carousel-block .carousel-block-shade a .block-inside .block-copy {
  width: 260px;
  align-self: flex-end;
}
@media screen and (min-width: 63em) {
  .carousel-wrapper .carousel-block .carousel-block-shade a .block-inside .block-copy {
    width: 475px;
  }
}
.carousel-wrapper .carousel-block .carousel-block-shade a .block-inside .block-copy .content-type-case-study {
  color: #00E422;
  font-weight: bold;
}
.carousel-wrapper .carousel-block .carousel-block-shade a .block-inside .block-copy .content-type-case-study:before {
  content: "  ";
}
.carousel-wrapper .carousel-block .carousel-block-shade a .block-inside .block-copy .content-type-the-lab {
  color: #E00E58;
  font-weight: bold;
}
.carousel-wrapper .carousel-block .carousel-block-shade a .block-inside .block-copy .content-type-the-lab:before {
  content: "  ";
}

section.services {
  margin: 4rem 0;
}
section.services ul {
  font-weight: bold;
  list-style: none;
  padding: 1rem 0 1rem 0;
}
@media screen and (min-width: 37em) {
  section.services ul {
    column-count: 2;
    column-gap: 3rem;
  }
}
@media screen and (min-width: 75em) {
  section.services ul {
    column-count: 3;
    column-gap: 5rem;
  }
}
section.services ul li {
  position: relative;
  padding: 0 0;
  margin-bottom: 1rem;
}

section.collaborators {
  margin: 4rem 0;
}
section.collaborators .content-container {
  padding: 0;
}
section.collaborators .collaborator-copy {
  background-color: #CDDC39;
  padding: 2rem;
  margin-bottom: 2rem;
}
section.collaborators .collaborator-copy a {
  color: #000;
  font-weight: bold;
}
section.collaborators .collaborator-copy a:hover {
  color: #662C92;
}
@media screen and (min-width: 75em) {
  section.collaborators .collaborator-copy {
    padding: 2rem 2rem 1rem 0;
  }
}
section.collaborators .collaborator-logos {
  background-color: #fff;
  padding: 0;
  display: flex;
  align-items: flex-end;
}
section.collaborators .collaborator-logos ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  list-style: none;
  padding-left: 0;
  width: 100%;
}
section.collaborators .collaborator-logos ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 20px;
}
section.collaborators .collaborator-logos ul li img {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 37em) {
  section.collaborators .collaborator-logos ul li {
    margin: 30px;
    width: 40%;
  }
}
@media screen and (min-width: 75em) {
  section.collaborators .collaborator-logos ul li {
    margin: 10px;
  }
  section.collaborators .collaborator-logos ul li img {
    max-width: 70%;
  }
}
@media screen and (min-width: 75em) {
  section.collaborators {
    background: linear-gradient(90deg, #CDDC39 50%, #ffffff 0%);
  }
  section.collaborators .collaborator-logos ul {
    justify-content: center;
  }
}

section.testimonials {
  padding-bottom: 5rem;
}
section.testimonials .testimonial-wrapper blockquote {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row {
  margin: 0;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row h3 {
  color: #662C92;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row img {
  width: 100%;
  height: auto;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row footer {
  text-align: right;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row footer .client-name {
  font: normal 900 1.5rem/normal Montserrat;
  color: #662C92;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row footer cite {
  font-size: 0.875rem;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row:first-child {
  justify-content: space-between;
  padding-bottom: 1.25rem;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-inner-row:last-child {
  justify-content: flex-end;
}
section.testimonials .testimonial-wrapper blockquote .testimonial-copy {
  padding-left: 0;
}

.case-study-grid .case-study-row {
  min-height: 200px;
}
.case-study-grid .case-study-row .case-study-copy {
  margin-top: 30px;
}
.case-study-grid .case-study-row .case-study-copy span {
  margin-bottom: 0px;
}
.case-study-grid .case-study-row .case-study-copy h3 {
  margin-top: 10px;
  margin-bottom: 50px;
  max-width: 100%;
  font-size: 20px;
}
.case-study-grid .case-study-row .case-study-copy h3 a {
  text-decoration: none;
  color: #2f2e41;
  max-width: 100%;
  font-size: 1em;
}
@media screen and (min-width: 37em) {
  .case-study-grid .case-study-row .case-study-copy h3 a {
    font-size: 1em;
  }
}
.case-study-grid .case-study-row .case-study-copy h3 a:hover {
  color: #00E422;
}
@media screen and (min-width: 37em) {
  .case-study-grid .case-study-row .case-study-copy h3 {
    font-size: 2em;
  }
}
.case-study-grid .lab-copy {
  margin-top: 30px;
}
.case-study-grid .lab-copy span {
  margin-bottom: 0px;
}
.case-study-grid .lab-copy h3 {
  margin-top: 10px;
  margin-bottom: 50px;
  max-width: 100%;
  font-size: 20px;
}
.case-study-grid .lab-copy h3 a {
  text-decoration: none;
  color: #2f2e41;
  max-width: 100%;
  font-size: 1em;
}
@media screen and (min-width: 37em) {
  .case-study-grid .lab-copy h3 a {
    font-size: 1em;
  }
}
.case-study-grid .lab-copy h3 a:hover {
  color: #E00E58;
}
@media screen and (min-width: 37em) {
  .case-study-grid .lab-copy h3 {
    font-size: 2em;
  }
}
.case-study-grid img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  margin-top: 25px;
}

.our-approach header.sub-header {
  background: #00E422;
}
.our-approach header.sub-header .sticky-header {
  border-color: #662C92;
}
.our-approach header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
  color: #662C92;
}
.our-approach header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #662C92;
}
.our-approach section.nurelm-process {
  padding: 1em 0 4em 0;
}
.our-approach section.nurelm-process .process-block {
  position: relative;
  min-height: 360px;
  top: 400px;
  opacity: 0;
}
.our-approach section.nurelm-process .process-block .our-process {
  padding: 2em;
}
.our-approach section.nurelm-process .process-block .our-process .block-heading {
  text-transform: uppercase;
}
.our-approach section.nurelm-process .process-block .our-process span {
  line-height: 25px;
}
@media screen and (min-width: 63em) {
  .our-approach section.nurelm-process .process-block {
    min-height: 410px;
  }
}
.our-approach section.nurelm-process .process-block .process-block-inner {
  display: block;
  width: 100%;
  position: absolute;
}
.our-approach section.nurelm-process .process-block .process-block-inner .react-bodymovin-container {
  text-align: center;
  padding: 0.5em;
  height: 150px;
}
.our-approach section.nurelm-process .process-block .process-block-inner .react-bodymovin-container svg {
  width: 100%;
  height: auto;
}
.our-approach section.nurelm-process .process-block:nth-child(1) {
  animation: move-icons-up 1s forwards 0.5s;
}
.our-approach section.nurelm-process .process-block:nth-child(2) {
  animation: move-icons-up 1s forwards 0.8s;
}
.our-approach section.nurelm-process .process-block:nth-child(3) {
  animation: move-icons-up 1s forwards 1.1s;
}
.our-approach section.nurelm-process .process-block:nth-child(4) {
  animation: move-icons-up 1s forwards 1.4s;
}
.our-approach section.services-component {
  background: #662C92;
  padding: 1em 0 4em 0;
}
.our-approach section.services-component h2 {
  color: #450A72;
  text-align: center;
  text-transform: uppercase;
}
.our-approach section.services-component div.service {
  color: #fff;
}
.our-approach section.services-component div.service p {
  line-height: 20px;
}
.our-approach section.services-component div.service a {
  text-decoration: underline;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.our-approach section.our-partners-component {
  padding: 1em 0 1em 0;
  display: flex;
  flex-direction: column;
}
.our-approach section.our-partners-component .our-partners-header {
  justify-content: center;
}
.our-approach section.our-partners-component .our-partners-header h2 {
  font-size: 2.5em;
}
@media screen and (min-width: 37em) {
  .our-approach section.our-partners-component .our-partners-header h2 {
    font-size: 3em;
  }
}
.our-approach section.our-partners-component .logos {
  text-align: center;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
}
.our-approach section.our-partners-component .logos .logo {
  padding: 1em;
}
.our-approach section.our-partners-component .logos .logo a {
  display: block;
}
.our-approach section.our-partners-component .logos .logo a img {
  width: 100%;
  height: auto;
  border: 0;
}
@media screen and (min-width: 37em) {
  .our-approach section.our-partners-component .logos .logo a img {
    width: 75%;
  }
}
@media screen and (min-width: 63em) {
  .our-approach section.our-partners-component .logos .logo a img {
    width: 65%;
  }
}
.our-approach section.our-partners-component .partner-case-study {
  text-align: center;
  margin-top: 100px;
}
.our-approach section.our-partners-component .partner-case-study h3 {
  font-size: 1.5em;
}
@media screen and (min-width: 37em) {
  .our-approach section.our-partners-component .partner-case-study h3 {
    font-size: 2em;
  }
}
.our-approach section.our-partners-component .partner-case-study h3 a {
  display: block;
  text-decoration: none;
  color: #2f2e41;
}
@media screen and (min-width: 37em) {
  .our-approach section.our-partners-component {
    padding: 3em 0 2em 0;
  }
}
.our-approach section.start-project-btn {
  padding-bottom: 3em;
}
.our-approach section.start-project-btn .project {
  margin-left: auto;
  margin-right: auto;
}
.our-approach section.start-project-btn .project a {
  padding: 0.5em 2em;
  display: block;
  border-width: 3px;
  font-size: 1em;
}
.our-approach section.start-project-btn .project a i {
  font-size: 0.75em;
}
@media screen and (min-width: 63em) {
  .our-approach section.start-project-btn .project a {
    font-size: 1.75em;
  }
  .our-approach section.start-project-btn .project a i {
    font-size: 1em;
  }
}

@keyframes move-icons-up {
  0% {
    top: 400px;
    opacity: 0;
  }
  50% {
    top: 250px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}
.our-company header.sub-header {
  background: #E00E58;
}
.our-company header.sub-header .sticky-header {
  border-color: #CDDC39;
}
.our-company header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
  color: #CDDC39;
}
.our-company header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #CDDC39;
}

.our-values {
  background-color: #fff;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.our-values .values-title h2 {
  margin-bottom: 0px;
}
.our-values h6 {
  margin-bottom: 0px;
}
.our-values p {
  width: 90%;
  margin-top: 0px;
}

.history-section {
  background-color: #CDDC39;
  text-align: center;
  padding-bottom: 2em;
}
.history-section .history-slider {
  position: relative;
}
.history-section .history-slider .arrows {
  display: inline-block;
  position: absolute;
  top: 35%;
  width: 100%;
}
.history-section .history-slider .arrows .arrow-left {
  float: left;
  margin-left: -35px;
}
.history-section .history-slider .arrows .arrow-right {
  float: right;
  margin-right: -60px;
}
@media screen and (min-width: 75em) {
  .history-section .history-slider .arrows .arrow-right {
    margin-right: -40px;
  }
}
.history-section .history-slider .arrows a .fa {
  color: #fff;
}
.history-section .history-slider #slide-one {
  display: none;
}
.history-section .history-slider #slide-one .slide-block-inside {
  margin: 2em;
  border: 5px solid #fff;
  padding: 1em;
}
.history-section .history-slider #slide-one .slide-block-inside .slide-one-copy {
  display: inline-block;
}
.history-section .history-slider #slide-one .slide-block-inside .slide-one-copy h1 {
  color: #662C92;
  margin-top: 0px;
  margin-bottom: 0px;
}
.history-section .history-slider #slide-one .slide-block-inside .slide-one-copy h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.history-section .history-slider #slide-one .slide-block-inside .slide-one-copy p {
  margin-top: 0px;
  margin-bottom: 50px;
}
.history-section .history-slider #slide-one .slide-block-inside .slide-one-copy span {
  float: right;
  position: absolute;
}
.history-section .history-slider #slide-one .slide-block-inside .slide-one-copy span a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}
.history-section .history-slider #slide-one:target {
  display: inline-block;
}
.history-section .history-slider #slide-two {
  display: none;
}
.history-section .history-slider #slide-two .slide-block-inside {
  margin: 2em;
  border: 5px solid #fff;
  padding: 1em;
}
.history-section .history-slider #slide-two .slide-block-inside .slide-two-copy {
  display: inline-block;
}
.history-section .history-slider #slide-two .slide-block-inside .slide-two-copy h1 {
  color: #662C92;
  margin-top: 0px;
  margin-bottom: 0px;
}
.history-section .history-slider #slide-two .slide-block-inside .slide-two-copy h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.history-section .history-slider #slide-two .slide-block-inside .slide-two-copy p {
  margin-top: 0px;
  margin-bottom: 50px;
}
.history-section .history-slider #slide-two .slide-block-inside .slide-two-copy span {
  float: right;
  position: absolute;
}
.history-section .history-slider #slide-two .slide-block-inside .slide-two-copy span a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}
.history-section .history-slider #slide-two:target {
  display: inline-block;
}
.history-section .history-slider #slide-three {
  display: none;
}
.history-section .history-slider #slide-three .fa {
  color: #fff;
}
.history-section .history-slider #slide-three .slide-three-copy {
  display: inline-block;
  position: relative;
  border: solid 5px #fff;
}
.history-section .history-slider #slide-three .slide-three-copy h1 {
  color: #662C92;
  margin-top: 0px;
  margin-bottom: 0px;
}
.history-section .history-slider #slide-three .slide-three-copy h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.history-section .history-slider #slide-three .slide-three-copy p {
  margin-top: 0px;
  margin-bottom: 50px;
}
.history-section .history-slider #slide-three .slide-three-copy span {
  float: right;
  color: #fff;
  font-weight: bold;
  padding-bottom: 20px;
  padding-right: 20px;
}
.history-section .history-slider #slide-three:target {
  display: inline-block;
}

div.contact-page header.sub-header {
  background: #00E422;
}
div.contact-page header.sub-header .sticky-header {
  border-color: #662C92;
}
div.contact-page header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
  color: #662C92;
}
div.contact-page header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #662C92;
}

.contact-form {
  margin: 5% auto;
  font-size: 2em;
}
.contact-form .field-group {
  padding: 0.5em 0;
}
.contact-form .field-group label {
  color: #662C92;
  display: block;
}
.contact-form .field-group input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid #662C92;
  color: #333;
  font-size: 0.8em;
  font-weight: 800;
  height: 40px;
  padding: 2% 5%;
  width: 90%;
}
.contact-form .field-group input:focus {
  border-bottom: 3px solid #00E422;
  transition: all 1s;
  outline: none;
}
.contact-form .field-group textarea {
  border: 3px solid #662C92;
  color: #333;
  font-size: 0.8em;
  min-height: 200px;
  padding: 5%;
  width: 90%;
}
.contact-form .field-group textarea:focus {
  border: 3px solid #00E422;
  transition: all 1s;
  outline: none;
}
.contact-form .field-group select {
  border: 3px solid #662C92;
  background: #fff;
  color: #662C92;
  font-size: 0.5em;
  font-weight: bold;
  height: 75px;
  width: 100%;
}
.contact-form .field-group select:focus {
  border: 3px solid #00E422;
  outline: none;
}
@media screen and (min-width: 37em) {
  .contact-form .field-group select {
    font-size: 1em;
  }
}
.contact-form button {
  border: 4px solid #662C92;
  outline: 0;
  background: #fff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1em;
  display: block;
  margin: 1em auto;
  padding: 0.5em 1em;
  width: 100%;
}
.contact-form button:hover {
  background: #662C92;
  color: #fff;
  cursor: pointer;
}
@media screen and (min-width: 37em) {
  .contact-form button {
    font-size: 1.4em;
  }
}

.success-message {
  color: #00E422;
}

.error-message {
  color: red;
}

.featured-block {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  top: -60px;
  position: relative;
  z-index: 10;
  height: 400px;
  width: 100%;
}
.featured-block .featured-block-shade {
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  width: 100%;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
.featured-block .featured-block-shade a {
  display: flex;
  display: -ms-flexbox;
  color: #fff;
  text-decoration: none;
  width: 100%;
}
.featured-block .featured-block-shade a:visited {
  color: #fff;
}
.featured-block .featured-block-shade a:hover .featured-block-inside {
  margin: 1em;
  padding: 1em;
  transition: all 0.5s;
  border: 4px solid #00E422;
}
.featured-block .featured-block-shade a:hover .featured-block-inside h6, .featured-block .featured-block-shade a:hover .featured-block-inside h2 {
  color: #00E422;
}
@media screen and (min-width: 63em) {
  .featured-block .featured-block-shade a:hover .featured-block-inside {
    margin: 2em;
    padding: 2em;
  }
}
.featured-block .featured-block-shade a .featured-block-inside {
  margin: 0em;
  border: 4px solid transparent;
  padding: 2em;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 63em) {
  .featured-block .featured-block-shade a .featured-block-inside {
    padding: 4em;
  }
}
.featured-block .featured-block-shade a .featured-block-inside .featured-block-copy {
  align-self: flex-end;
  text-align: left;
  width: 100%;
}
.featured-block .featured-block-shade a .featured-block-inside .featured-block-copy p {
  margin: 0px;
  font-size: 12px;
}
@media screen and (min-width: 37em) {
  .featured-block .featured-block-shade a .featured-block-inside .featured-block-copy p {
    font-size: 18px;
  }
}
.featured-block .featured-block-shade a .featured-block-inside .featured-block-copy h2 {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 1.3em;
  line-height: 1.1em;
}
@media screen and (min-width: 37em) {
  .featured-block .featured-block-shade a .featured-block-inside .featured-block-copy h2 {
    font-size: 3em;
    line-height: 1.2em;
  }
}
.featured-block .featured-block-shade a .featured-block-inside .featured-block-copy h6 {
  margin-bottom: 10px;
  font-size: 0.7em;
}
@media screen and (min-width: 37em) {
  .featured-block .featured-block-shade a .featured-block-inside .featured-block-copy h6 {
    font-size: 1em;
  }
}
@media screen and (min-width: 37em) {
  .featured-block {
    min-height: 650px;
  }
}

.our-work header.sub-header {
  background: #662C92;
}
.our-work header.sub-header .sticky-header {
  border-color: #00E422;
}
.our-work header.sub-header .hero-wrapper .container .shared-hero .hero-wrap {
  padding-bottom: 2em;
}
@media screen and (min-width: 63em) {
  .our-work header.sub-header .hero-wrapper .container .shared-hero .hero-wrap {
    padding-bottom: 3.5em;
  }
}

.work-content-grid {
  -ms-flex-wrap: wrap;
  top: -60px;
}
.work-content-grid .filtered-work {
  display: none;
  display: -ms-flexbox;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 0px;
}
.work-content-grid .filtered-work h6 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  display: -ms-flexbox;
}
.work-content-grid .filtered-work h6:hover {
  color: #00E422;
}
@media screen and (min-width: 63em) {
  .work-content-grid .filtered-work h6 {
    font-size: 1em;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
@media screen and (min-width: 63em) {
  .work-content-grid .filtered-work {
    justify-content: flex-start;
  }
}
.work-content-grid .work-col a {
  text-decoration: none;
}
.work-content-grid .work-col .work-col-copy {
  color: #2f2e41;
  width: 100%;
}
.work-content-grid .work-col .work-col-copy h6 {
  margin: 0px;
}
.work-content-grid .work-col .work-col-copy img {
  width: 100%;
  height: 100%;
  border: none;
}
.work-content-grid .work-col .work-col-copy p {
  width: 90%;
}
.work-content-grid .work-col .work-col-copy span {
  color: #662C92;
  font-weight: bold;
}

.featured-image {
  max-width: 100%;
}

.our-work-details header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading, .our-work-details header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-copy {
  color: #2f2e41;
}
.our-work-details .details-content a {
  text-decoration: none;
  color: #2f2e41;
  font-weight: bold;
}
.our-work-details .details-content a:hover {
  color: #00E422;
}
.our-work-details .details-content .details-image img {
  width: 100%;
  height: auto;
}
.our-work-details .details-content img {
  max-width: 100%;
  height: auto;
  padding: 10px 0 10px 0;
}
.our-work-details .details-content .alignright {
  margin: auto;
  display: block;
}
@media screen and (min-width: 63em) {
  .our-work-details .details-content .alignright {
    float: right;
    padding-left: 15px;
  }
}
.our-work-details .details-content .alignleft {
  margin: auto;
  display: block;
}
@media screen and (min-width: 63em) {
  .our-work-details .details-content .alignleft {
    float: left;
    padding-right: 15px;
  }
}
.our-work-details .details-content .aligncenter {
  margin: auto;
  display: block;
}
.our-work-details .details-content .wp-caption-text {
  font-size: 0.75em;
}
.our-work-details .details-content .details-text .work-details-col {
  width: 100%;
  height: auto;
}
.our-work-details .details-content .details-text .work-details-col h5 {
  font-size: 1.5em;
}
@media screen and (min-width: 37em) {
  .our-work-details .details-content .details-text .work-details-col h5 {
    font-size: 2em;
  }
}
.our-work-details .details-content .details-text .work-details-col h4 {
  font-size: 1.5em;
  line-height: 1.5em;
}
@media screen and (min-width: 37em) {
  .our-work-details .details-content .details-text .work-details-col h4 {
    font-size: 2.5em;
  }
}
.our-work-details .details-content .details-text .work-details-col h2 {
  text-transform: uppercase;
  font-size: 2em;
}
@media screen and (min-width: 37em) {
  .our-work-details .details-content .details-text .work-details-col h2 {
    font-size: 3em;
  }
}
.our-work-details .details-content .details-text .work-details-col h3 {
  text-transform: uppercase;
  font-size: 1em;
}
@media screen and (min-width: 37em) {
  .our-work-details .details-content .details-text .work-details-col h3 {
    font-size: 2em;
  }
}
.our-work-details .details-content .details-text .work-details-col h6 {
  margin-bottom: 10px;
}
.our-work-details .details-content .details-text .work-details-col ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.our-work-details .details-content .details-text .work-details-col li {
  line-height: 30px;
}
.our-work-details .details-content .details-text .work-details-col li i {
  font-size: 20px;
}
.our-work-details .details-content .details-text .work-details-col p {
  line-height: 1.5em;
}
@media screen and (min-width: 37em) {
  .our-work-details .details-content .details-text .work-details-col p {
    line-height: 1.7em;
  }
}

.more-work {
  background-color: #2f2e41;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
}
.more-work .centered-work {
  justify-content: center;
}
.more-work .centered-work h3 {
  color: #fff;
}
.more-work .more-work-row {
  margin-left: 4rem;
  margin-right: 4rem;
}
.more-work .more-work-row .more-work-col {
  padding: 10px;
}
.more-work .more-work-row .more-work-col .more-work-copy {
  color: #fff;
  width: 100%;
  padding: 20px 0;
}
.more-work .more-work-row .more-work-col .more-work-copy h6 {
  padding: 0;
  margin: 0;
}
.more-work .more-work-row .more-work-col .more-work-copy h5 {
  padding: 5px 0;
  margin: 0;
}
.more-work .more-work-row .more-work-col .more-work-copy p {
  padding: 0;
  margin: 0;
}
.more-work .more-work-row .more-work-col .more-work-copy span {
  padding: 0;
  margin: 0;
  color: #00E422;
}
.more-work .more-work-row .more-work-col img {
  width: 100%;
  height: auto;
}

div.not-found header.sub-header {
  background: #00E422;
}
div.not-found header.sub-header .sticky-header {
  border-color: #662C92;
}
div.not-found header.sub-header .sticky-header.scrolling-header .innovate-cta a:hover {
  color: #662C92;
}
div.not-found header.sub-header .hero-wrapper .container .shared-hero .hero-page-title i {
  color: #662C92;
}

.content-container.mobile-app-page {
  padding-bottom: 0px;
}

.mobile-apps header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading, .mobile-apps header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-copy {
  color: #2f2e41;
}
.mobile-apps .mobile-apps-content p {
  font-size: 1.2em;
}
.mobile-apps .hero-image {
  background: url("./images/test-mobile-app.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 40vw;
  height: auto;
}
.mobile-apps .mobile-apps-related {
  border-top: 1px solid;
  border-color: #2f2e41;
  text-align: right;
}
.mobile-apps .mobile-apps-related .blog-snippet {
  padding-top: 10px;
  padding-bottom: 10px;
}
.mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse {
  flex-direction: row-reverse;
  margin: 0;
}
.mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse .blog-blurb h2 {
  font-size: 1em;
  font-weight: 800;
}
@media screen and (min-width: 63em) {
  .mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse .blog-blurb h2 {
    font-size: 1.5em;
  }
}
.mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse .blog-blurb h2 a {
  text-decoration: none;
  color: black;
}
@media screen and (min-width: 63em) {
  .mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse .blog-blurb h2 a:hover {
    color: #E00E58;
  }
}
.mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse .blog-blurb h2 a:hover:after {
  font-family: FontAwesome;
  font-size: 0.5em;
  display: table-cell;
  vertical-align: top;
  line-height: 18px;
  content: "\f061";
  color: #00E422;
  padding-left: 10px;
}
@media screen and (min-width: 63em) {
  .mobile-apps .mobile-apps-related .blog-snippet .snippet-row-reverse .blog-blurb h2 a:hover:after {
    content: none;
  }
}
.mobile-apps .mobile-apps-related .blog-snippet-image img {
  height: auto;
  width: auto;
  max-width: 125px;
  max-height: 125px;
  padding-top: 20px;
}
@media screen and (min-width: 63em) {
  .mobile-apps .mobile-apps-related .blog-snippet-image img {
    max-width: 300px;
    max-height: 300px;
    float: left;
  }
}
@media screen and (min-width: 75em) {
  .mobile-apps .mobile-apps-related .blog-snippet-image img {
    max-width: 350px;
    max-height: 350px;
  }
}

.landing-page header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading, .landing-page header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-copy {
  color: #2f2e41;
}
.landing-page .hero-image {
  background-size: cover;
  width: 100%;
  min-height: 40vw;
  height: auto;
  background-repeat: no-repeat;
}
.landing-page .form {
  color: white;
  background-color: #662C92;
}
.landing-page .form .form-heading {
  padding-top: 15px;
  text-align: center;
}
.landing-page .form .form-heading a {
  text-decoration: none;
  color: white;
}
.landing-page .form .form-heading a:hover {
  color: #E00E58;
}
.landing-page .form .form-body {
  background-color: #662C92;
}
.landing-page .form .form-body label {
  color: #00E422;
}
.landing-page .form .form-body button:hover {
  background: #CDDC39;
  color: #000;
}
.landing-page .form .form-body .button-spinner {
  background-color: #fff;
}
.landing-page .form .form-body .contact-form.content-container {
  margin-bottom: 0px;
}
.landing-page .form .error-message {
  color: #FF9D9D;
}
.landing-page .form .success-message {
  color: #CDDC39;
}

.center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
  opacity: 50%;
}

.button-img-wrapper {
  position: relative;
}
.button-img-wrapper .button-img {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
}

.text-center {
  text-align: center !important;
}

.landing-page-webinars {
  background-color: #CDDC39;
}
.landing-page-webinars h2 {
  text-transform: uppercase;
}
.landing-page-webinars iframe {
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 63em) {
  .hero-webinar {
    padding: 50px 175px;
  }
}

.content-container.mobile-app-page {
  padding-bottom: 0px;
}

.landing-page header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-heading, .landing-page header.sub-header .hero-wrapper .container .shared-hero .hero-wrap .hero-copy {
  color: #2f2e41;
}
.landing-page .hero-image {
  background-size: cover;
  width: 100%;
  min-height: 40vw;
  height: auto;
  background-repeat: no-repeat;
}
.landing-page .landing-page-content {
  padding: 1em;
}
.landing-page .landing-page-content .wp-block-columns {
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 1em;
  column-gap: 1em;
}
@media screen and (min-width: 75em) {
  .landing-page .landing-page-content .wp-block-columns {
    margin: 0 2em;
    column-gap: 2em;
  }
}
.landing-page .landing-page-content .wp-block-columns p.landing-text {
  font-size: 20px;
}
.landing-page .landing-page-content .wp-block-columns button.subscribe-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
  align-items: center;
  height: 57px;
  padding: 0.75rem;
  border: none;
  color: white;
  font: normal 900 1.75rem/normal Montserrat;
  background-color: #662C92;
  cursor: pointer;
}
@media screen and (min-width: 63em) {
  .landing-page .landing-page-content .wp-block-columns button.subscribe-btn {
    width: 230px;
    margin-right: 30px;
  }
}
.landing-page .landing-page-content .wp-block-columns button.subscribe-btn:hover {
  background-color: #CDDC39;
  color: black;
}
.landing-page .landing-page-content .wp-block-columns button.subscribe-btn.btn-loading {
  color: #4A1671;
  background-color: #662C92;
  cursor: not-allowed;
}
.landing-page .landing-page-content .wp-block-columns button.subscribe-btn.btn-loading img {
  height: 26px;
  width: 26px;
  animation: rotate-spinner 1s infinite;
  opacity: 1;
}
.landing-page .landing-page-content .wp-block-columns button.subscribe-btn span {
  display: none;
}
@media screen and (min-width: 63em) {
  .landing-page .landing-page-content .wp-block-columns button.subscribe-btn span {
    display: block;
  }
}
.landing-page .landing-page-content .wp-block-columns .subscribe-label {
  text-align: start;
  font: normal bold 16px Montserrat;
}
.landing-page .landing-page-content .wp-block-columns input {
  border: 5px solid #662C92;
}
.landing-page .landing-page-content .wp-block-columns .subscribe-input {
  height: 45px;
}
.landing-page .landing-page-content .wp-block-columns .subscribe-input-area {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-right: 10px;
}
@media screen and (min-width: 63em) {
  .landing-page .landing-page-content .wp-block-columns .subscribe-input-area {
    width: 40%;
  }
}
.landing-page .landing-page-content .wp-block-columns .subscribe-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px;
}
@media screen and (min-width: 37em) {
  .landing-page .landing-page-content .wp-block-columns .subscribe-form {
    margin-top: 10px;
    height: 70px;
  }
}
.landing-page .landing-page-content .wp-block-columns .next-webinar {
  font-weight: 700;
}
.landing-page .landing-page-content .wp-block-spacer {
  height: 50px;
}

@keyframes rotate-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.purple-section-header {
  background-color: #4A1671;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}
.purple-section-header h2 {
  font-size: 1.8rem;
  line-height: 2rem;
}
@media screen and (min-width: 63em) {
  .purple-section-header h2 {
    font-size: 2rem;
    line-height: 1.85rem;
  }
}

@media screen and (min-width: 63em) {
  .narrow-form .container {
    width: 60%;
  }
}
.narrow-form .button-spinner {
  background-color: #fff;
  padding: 0;
  border: none;
  margin: 1.4em auto;
}
.narrow-form .button-spinner img {
  height: 43px;
  padding: 5px;
}
.narrow-form .error-message {
  color: #FF9D9D;
}
.narrow-form .success-message {
  color: #CDDC39;
}

.controlled-form .form-body {
  padding-top: 25px;
  background-color: #662C92;
}
.controlled-form .form-body button:hover {
  background: #CDDC39;
  color: #000;
}
.controlled-form .row {
  padding-bottom: 20px;
}
.controlled-form .field-group {
  padding: 0.5em 0;
}
.controlled-form .field-group label {
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}
@media screen and (min-width: 37em) {
  .controlled-form .field-group label {
    font-size: 20px;
  }
}
.controlled-form .field-group input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid #662C92;
  color: #333;
  font-size: 0.8em;
  font-weight: 800;
  height: 45px;
  width: 100%;
}
.controlled-form .field-group input:focus {
  border-bottom: 3px solid #00E422;
  transition: all 1s;
  outline: none;
}
.controlled-form .field-group input[type=checkbox] {
  opacity: 0;
}
.controlled-form .field-group input[type=checkbox] + label {
  position: relative;
  height: 20px;
  padding-left: 60px !important;
  cursor: pointer;
  display: inline-block;
}
.controlled-form .field-group input[type=checkbox] + label:before, .controlled-form .field-group input[type=checkbox] + label:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
}
.controlled-form .field-group input[type=checkbox]:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.controlled-form .field-group input[type=checkbox]:not(:checked) + label:after {
  width: 43px;
  height: 43px;
  border: 2px solid #fff;
  top: 0px;
}
.controlled-form .field-group input[type=checkbox]:checked + label:before {
  top: 2px;
  left: 3px;
  width: 12px;
  height: 24px;
  margin-top: 3px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 4px solid #662C92;
  border-bottom: 4px solid #662C92;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.controlled-form .field-group input[type=checkbox]:checked + label:after {
  top: 0;
  width: 43px;
  height: 43px;
  border: 2px solid white;
  background-color: white;
  z-index: 0;
  border-radius: 2px;
}
.controlled-form .field-group .checkbox-row input {
  display: none;
}
.controlled-form .field-group .checkbox-row label {
  font-weight: 500;
  padding-top: 5px;
}
.controlled-form button {
  border: 4px solid #CDDC39;
  color: #CDDC39;
  outline: 0;
  background-color: #662C92;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4em;
  display: block;
  margin: 1em auto;
  padding: 0.5em 1em;
  width: 100%;
}
.controlled-form button:hover {
  cursor: pointer;
}

.button-spinner {
  text-align: center;
  border: 4px solid #662C92;
  opacity: 0.4;
  padding: 0.5em 1em;
  margin: 1.5em auto;
}
.button-spinner img {
  animation: rotate-spinner 1s infinite;
  opacity: 0.4;
}

.loader-spinner img {
  animation: rotate-spinner 1s infinite;
  opacity: 0.3;
  width: 150px;
  height: 150px;
}
@media screen and (min-width: 63em) {
  .loader-spinner img {
    width: 250px;
    height: 250px;
  }
}

@keyframes rotate-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.loader-rotating {
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-scroll .loader-rotating {
  height: 348px;
  width: 100%;
}
@media screen and (min-width: 63em) {
  .carousel-scroll .loader-rotating {
    height: 643px;
  }
}

.case-study-grid .loader-rotating {
  height: 840px;
  width: 100%;
}
@media screen and (min-width: 37em) {
  .case-study-grid .loader-rotating {
    height: 900px;
  }
}
@media screen and (min-width: 75em) {
  .case-study-grid .loader-rotating {
    height: 1000px;
  }
}

.more-work-row .loader-rotating {
  height: 2076px;
}
@media screen and (min-width: 37em) {
  .more-work-row .loader-rotating {
    height: 585px;
  }
}

.loader-text {
  background-color: #e1e1e1;
  height: 21px;
  width: 60%;
  animation: text-block-change 1s infinite;
}

@keyframes text-block-change {
  0% {
    opacity: 0.4;
    width: 60%;
  }
  50% {
    opacity: 0.7;
    width: 70%;
  }
  100% {
    opacity: 0.4;
    width: 60%;
  }
}
.loader-thelab .loader-rotating {
  height: 3142px;
  width: 100%;
  padding-top: 200px;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (min-width: 37em) {
  .loader-thelab .loader-rotating {
    height: 2400px;
  }
}
@media screen and (min-width: 63em) {
  .loader-thelab .loader-rotating {
    height: 2300px;
  }
}
@media screen and (min-width: 75em) {
  .loader-thelab .loader-rotating {
    height: 1900px;
  }
}

@media screen and (min-width: 48em) {
  .container {
    width: 48rem;
  }
}
@media screen and (min-width: 48em) and (min-width: 64em) {
  .container {
    width: 64rem;
  }
}
@media screen and (min-width: 48em) and (min-width: 76em) {
  .container {
    width: 75rem;
  }
}
.wp-embed-responsive .wp-has-aspect-ratio iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.wp-block-embed iframe {
  max-width: 100%;
}

.content-container {
  padding: 1em 0;
}
.content-container.no-padding {
  padding: 0 0;
}
.content-container .row {
  margin-left: 1.5em;
  margin-right: 1.5em;
}
.content-container .row h2.section-heading {
  margin: 0.5em 0;
}
.content-container .row h3.block-heading {
  margin: 0.25em 0;
}
@media screen and (min-width: 49em) {
  .content-container .row {
    margin-left: 2em;
    margin-right: 2em;
  }
}

/*# sourceMappingURL=App.css.map */
